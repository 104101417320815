var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [[_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Create Status ")])]], 2)]), _c('vue-good-table', {
    attrs: {
      "styleClass": "vgt-table striped",
      "mode": "remote",
      "totalRows": _vm.totalRecords,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$column, _props$row, _props$column2, _props$row2, _props$column3, _props$row3, _props$row4, _props$row5, _props$row$color_code;
        return [(props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'format_name' ? [_c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : _props$row.name))])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'format_sequence' ? [_c('b-badge', {
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" " + _vm._s(props.row.sequence) + " ")])] : _vm._e(), props.column.field === 'format_status' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": _vm.statusVariant(props.row.status)
          }
        }, [_vm._v(" " + _vm._s(props !== null && props !== void 0 && (_props$row2 = props.row) !== null && _props$row2 !== void 0 && _props$row2.status ? "Active" : "Inactive") + " ")])], 1) : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column3 = props.column) === null || _props$column3 === void 0 ? void 0 : _props$column3.field) === 'format_type' ? [(props === null || props === void 0 ? void 0 : (_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.type) == 1 ? [_c('b-badge', {
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v("General")])] : (props === null || props === void 0 ? void 0 : (_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.type) == 2 ? [_c('b-badge', {
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v("Success")])] : (props === null || props === void 0 ? void 0 : (_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : _props$row5.type) == 3 ? [_c('b-badge', {
          attrs: {
            "variant": "light-danger"
          }
        }, [_vm._v("Dead")])] : _vm._e()] : _vm._e(), props.column.field === 'format_lead_count' ? [_c('b-badge', {
          staticClass: "cursor-pointer",
          attrs: {
            "variant": "light-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.filterLead(props.row.id);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.leads_count) + " ")])] : _vm._e(), props.column.field === 'format_color' ? [_c('b-badge', {
          staticClass: "badge",
          style: {
            color: 'white',
            backgroundColor: (_props$row$color_code = props.row.color_code) !== null && _props$row$color_code !== void 0 ? _props$row$color_code : '#7367f0'
          },
          attrs: {
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(props.row.color_name) + " ")])] : _vm._e(), props.column.field === 'action' ? _c('span', [_vm.$permissionAbility(_vm.LEAD_STATUS_EDIT, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "Edit2Icon",
            "title": "Edit",
            "variant": "warning",
            "size": "16"
          }
        })], 1)] : _vm._e(), _vm.$permissionAbility(_vm.LEAD_STATUS_DELETE, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "TrashIcon",
            "variant": "danger",
            "title": "Delete",
            "size": "16"
          }
        })], 1)] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-status-form",
      "centered": "",
      "title": _vm.modelType == 'editModel' ? 'Edit Status' : 'Create Status',
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "leadStatusValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Title",
      "label-for": "name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "title",
      "vid": "name",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Status"
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Sequence",
      "label-for": "sequence"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "sequence",
      "vid": "sequence"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          attrs: {
            "option-disabled": "disable",
            "id": "sequence",
            "placeholder": "Select Sequence",
            "options": _vm.leadSequenceOptions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name"
          },
          on: {
            "open": _vm.filteredLeadSequenceStatus
          },
          model: {
            value: _vm.selectSequence,
            callback: function callback($$v) {
              _vm.selectSequence = $$v;
            },
            expression: "selectSequence"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Color",
      "label-for": "color_name"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "color_name",
      "vid": "color_name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "id": "color_code",
            "placeholder": "Select Color",
            "options": _vm.selectColorOption,
            "label": "name"
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(_ref4) {
              var name = _ref4.name,
                value = _ref4.value;
              return [_c('b-badge', {
                style: {
                  background: value
                },
                attrs: {
                  "pill": ""
                }
              }, [_vm._v(_vm._s(name))])];
            }
          }], null, true),
          model: {
            value: _vm.selectColorValue,
            callback: function callback($$v) {
              _vm.selectColorValue = $$v;
            },
            expression: "selectColorValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Status Type",
      "label-for": "type"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "status type",
      "vid": "type"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "placeholder": "Select Type",
            "options": _vm.leadStatusTypeConstants,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name"
          },
          model: {
            value: _vm.selectStatusTypeValue,
            callback: function callback($$v) {
              _vm.selectStatusTypeValue = $$v;
            },
            expression: "selectStatusTypeValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-for": "status"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "status",
      "vid": "status"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('v-select', {
          attrs: {
            "id": "status",
            "placeholder": "Select Status",
            "options": _vm.statusValueOption,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name"
          },
          model: {
            value: _vm.selectStatusValue,
            callback: function callback($$v) {
              _vm.selectStatusValue = $$v;
            },
            expression: "selectStatusValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isLeadStatusFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }